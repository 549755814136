import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, combineLatest, filter, firstValueFrom, map, Observable, Subject, switchMap, take, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { BooksProvider } from '../providers/books.provider';
import { UserCompletedActivities, UserProvider } from '../providers/user.provider';
import { Router } from '@angular/router';
import { Book } from '../models/book.model';
import { UserBook, UserBooks } from '../models/user-book.model';

export const GUEST_BOOK_READ_LIMIT = 3000; // Number of book that a guest can read before forcing him to login

export interface BookData {
  book: Book;
  userBook: UserBook;
  userBooks: UserBooks;
  userCompletedActivities: UserCompletedActivities[];
}
/**
 * The purpose of this service is to keep track & data of which book is currently open
 * on the Book Details / Reader or Book Completion pages, so that you don't have to call up the api every time.
 */
@Injectable({ providedIn: 'root' })
export class BookService {
  constructor(
    private booksProvider: BooksProvider,
    private userProvider: UserProvider,
    private router: Router,
  ) {}

  book$: BehaviorSubject<Book | null> = new BehaviorSubject<Book | null>(null);
  userBook$: Subject<UserBook | null> = new BehaviorSubject<UserBook | null>(null);
  userBooks$: Subject<UserBooks | null> = new BehaviorSubject<UserBooks | null>(null);
  userCompletedActivities$: Subject<UserCompletedActivities[] | null> = new BehaviorSubject<UserCompletedActivities[] | null>(null);

  // Combine book$ & userBook$ & avoid sending null values
  bookData$: Observable<BookData> = combineLatest([this.book$, this.userBook$, this.userBooks$, this.userCompletedActivities$]).pipe(
    map(([book, userBook, userBooks, userCompletedActivities]) => {
      return { book, userBook, userBooks, userCompletedActivities } as {
        book: Book;
        userBook: UserBook;
        userBooks: UserBooks;
        userCompletedActivities: UserCompletedActivities[];
      };
    }),
    filter(
      ({ book, userBook, userBooks, userCompletedActivities }) =>
        book !== null && userBook !== null && userBooks !== null && userCompletedActivities !== null,
    ),
  );

  bookLimitReached$ = this.bookData$.pipe(
    filter(bookData => !!bookData.userBooks),
    map(bookData => {
      // Check if book has been read
      if (bookData.userBooks.books.includes(bookData.book.uuid)) {
        return false;
      }

      // Check limit
      return bookData.userBooks && bookData.userBooks?.books?.length >= GUEST_BOOK_READ_LIMIT;
    }),
  );

  bookComplete$ = new BehaviorSubject<boolean>(false);

  async refreshUserBooks$(): Promise<void> {
    const userBooks = await firstValueFrom(this.userProvider.getUserFinishedBooks());
    this.userBooks$.next(userBooks);
  }

  async refreshUserBookInfo$(uuid: string): Promise<void> {
    const userBook = await firstValueFrom(this.userProvider.getUserBookInfo(uuid));
    this.userBook$.next(userBook);
  }

  // We need to refresh the completed activities api everytime a user complete an activity
  async refreshUserCompletedActivities$(uuid: string, profileId: string): Promise<void> {
    const userCompletedActivities = await firstValueFrom(this.userProvider.getUserCompletedActivities(profileId, uuid));
    this.userCompletedActivities$.next(userCompletedActivities);
  }

  async getBook(uuid: string): Promise<void> {
    // Don't reload the same book if it's already loaded - we just need to get updated data of user books
    if (this.book$.value?.uuid === uuid) {
      void this.refreshUserBooks$();
      return Promise.resolve();
    } else {
      this.book$.next(null);
      this.userBook$.next(null);
    }

    combineLatest([this.booksProvider.getBook(uuid), this.userProvider.getUserBookInfo(uuid), this.userProvider.getUserFinishedBooks()])
      .pipe(
        take(1),
        switchMap(([book, userBook, userBooks]) => {
          return this.userProvider.getUserCompletedActivities(userBook.profileId, uuid).pipe(
            catchError((error: HttpErrorResponse) => {
              void this.router.navigate(['/book-error'], {
                state: { errorCode: error.status },
              });

              return throwError(() => error);
            }),
            map(userCompletedActivities => {
              return {
                book,
                userBook,
                userBooks,
                userCompletedActivities,
              };
            }),
          );
        }),
        catchError((error: HttpErrorResponse) => {
          void this.router.navigate(['/book-error'], {
            state: { errorCode: error.status },
          });

          return throwError(() => error);
        }),
      )
      .subscribe(async ({ book, userBook, userBooks, userCompletedActivities }) => {
        this.book$.next(book);
        this.userBook$.next(userBook);
        this.userBooks$.next(userBooks);
        this.userCompletedActivities$.next(userCompletedActivities);
      });
  }

  clear(): void {
    this.book$.next(null);
    this.userBook$.next(null);
  }
}
