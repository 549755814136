import { Injectable } from '@angular/core';
import { ProfileState } from '../store/profile.state';
import { UserState, UserType } from '../store/user.state';
import { ProfileProvider } from '../providers/profile.provider';
import { BookShelfService } from './book-shelf.service';
import { firstValueFrom } from 'rxjs';
import { Profile } from '../models/profile.model';
import { BookFiltersState } from '../store/book-filters.state';
import { Feature, LoggerService } from './logger.service';
import { BookService } from './book-service';

@Injectable({ providedIn: 'root' })
export class ProfileService {
  constructor(
    private profileState: ProfileState,
    private userState: UserState,
    private profileProvider: ProfileProvider,
    private bookShelveService: BookShelfService,
    private bookFiltersState: BookFiltersState,
    private loggerService: LoggerService,
    private bookService: BookService,
  ) {}

  async refreshProfile(): Promise<void> {
    // Only logged users can accumulate points
    if (this.userState.user$.value.userType === UserType.GUEST) {
      return Promise.resolve();
    }

    const profiles: Array<Profile> = await firstValueFrom(this.profileProvider.getUserProfiles());

    if (profiles) {
      await this.profileState.set({
        currentProfile: profiles.find(profile => profile.id === this.profileState.currentProfile$.value?.id),
        profiles: profiles,
      });
    } else {
      console.log('No profile for this user');
    }

    return Promise.resolve();
  }

  async reset(): Promise<void> {
    await this.profileState.reset();
  }

  async addAndSetProfile(profile: Profile): Promise<void> {
    await this.profileState.set({
      currentProfile: profile,
      profiles: [...this.profileState.profiles$.value, profile],
    });
    await this.setAgeFilter(profile);
    void this.bookShelveService.initialize();
    this.bookService.clear();
  }

  async updateProfile(profile: Profile): Promise<void> {
    await this.profileState.set({
      currentProfile: profile,
      profiles: this.profileState.profiles$.value.map(storedProfile => (storedProfile.id === profile.id ? profile : storedProfile)),
    });
  }

  async deleteProfile(profileId: string): Promise<void> {
    await this.profileState.set({
      currentProfile: null,
      profiles: this.profileState.profiles$.value.filter(profiles => profiles.id !== profileId),
    });
  }

  async setProfile(profile: Profile | null): Promise<void> {
    const oldProfileId = this.profileState.currentProfile$.value?.id;

    if (profile) {
      await this.profileState.set({
        currentProfile: profile,
        profiles: this.profileState.profiles$.value,
      });
    } else {
      await this.profileState.reset();
    }

    if (profile?.id !== oldProfileId) {
      void this.bookShelveService.initialize();
      profile && (await this.setAgeFilter(profile));
      this.bookService.clear();
    }
  }

  private async setAgeFilter(profile: Profile): Promise<void> {
    const selectedGradeId = this.bookFiltersState.bookFilters$.value.selectedGrade?.id;

    const newSelectedGrade = this.bookFiltersState.bookFilters$.value.grades.find(
      grade => grade.maxAge === profile.maxAge && grade.minAge === profile.minAge,
    );

    if (!newSelectedGrade && (profile.maxAge || profile.minAge)) {
      this.loggerService.error('ProfileService::setAgeFilter', {
        feature: Feature.PROFILE,
        context: `No age filter found for profile id ${profile.id}, maxAge: ${profile.maxAge}, minAge : ${profile.minAge}`,
      });
    }

    if (newSelectedGrade?.id !== selectedGradeId) {
      await this.bookFiltersState.set({ selectedGrade: newSelectedGrade });
    }
  }
}
