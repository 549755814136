import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { UrlBuilder } from '../utils/url-builder';
import { Profile, ProfileResponseRequest } from '../models/profile.model';

enum ApiEndpoint {
  CreateGetProfile = '/profiles',
  UpdateProfile = '/profiles/:profileId',
  DeleteProfile = '/profiles/:id',
}

@Injectable({ providedIn: 'root' })
export class ProfileProvider {
  constructor(private http: HttpClient) {}

  createUserProfile(profile: Partial<Profile>): Observable<Profile> {
    return this.http.post<Profile>(environment.usersBaseUrl + ApiEndpoint.CreateGetProfile, profile);
  }

  updateUserProfile(profile: Profile): Observable<Profile> {
    const url = new UrlBuilder(ApiEndpoint.UpdateProfile).setUrlParams({ profileId: profile.id as string }).getUrl();

    return this.http.patch<Profile>(environment.usersBaseUrl + url, profile);
  }

  getUserProfiles(): Observable<Array<Profile>> {
    return this.http.get<ProfileResponseRequest>(environment.usersBaseUrl + ApiEndpoint.CreateGetProfile).pipe(map(response => response.profiles));
  }

  deleteUserProfile(profileId: string): Observable<Array<Profile>> {
    const url = new UrlBuilder(ApiEndpoint.DeleteProfile).setUrlParams({ id: profileId }).getUrl();

    return this.http.delete<ProfileResponseRequest>(environment.usersBaseUrl + url).pipe(map(response => response.profiles));
  }
}
